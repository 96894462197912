import { gql } from '@apollo/client';

export const PROJECT = {
    GET_FEATURED_ITEMS: gql`
        query GetFeaturedItems {
            getFeaturedItems {
                button_text
                image
                link
                subtitle
                title
                mobile_image
            }
        }
    `,
    GET_NOTIFICATION_DATA: gql`
        query getNotificationData {
            getNotification {
                active
                link
                message
                type
                button_text
            }
        }
    `,

    GET_LIST: gql`
        query GetProjects($limit: Int, $status: ProjectStatus) {
            retrieveProjects(limit: $limit, status: $status) {
                data {
                    id
                    slug
                    announced
                    events {
                        id
                        type
                        crowdfunding_type
                        name
                        start_date
                        chainId
                        saft_url
                        contract {
                            abi
                            address
                        }
                        challenge {
                            question
                            answers
                        }
                        token_price
                        total_allocation
                        refund_deadline
                        results_date
                        results_url
                        answer
                        uid
                        register_url
                        prize
                        how_to_participate
                        is_hidden
                        marketing_event {
                            image
                            title
                            link
                        }
                        ama_event {
                            start_date
                            end_date
                            title
                            link
                            type
                        }
                        event_details {
                            answer_hash
                            min_tier
                            durations
                            hardcap
                            vesting {
                                start
                                cliff
                                duration
                                initial_unlock_percent
                            }
                        }
                    }
                    teaser
                    subtitle
                    status
                    name
                    logo
                    square_logo
                    hero
                    social_platforms {
                        type
                        url
                    }
                    token {
                        ticker
                        network
                        tge
                        fully_diluted_valuation
                        initial_market_cap
                        initial_market_cap_ex_liquidity
                        total_supply
                    }
                    description
                    explanation
                    faqs {
                        question
                        answer
                    }
                    links {
                        roadmap {
                            href
                            image
                        }
                        team {
                            href
                            image
                        }
                        tokenomics {
                            href
                            image
                        }
                        website {
                            href
                            image
                        }
                    }
                    created_at
                }
            }
        }
    `,
    GET_TOKEN_PRICES: gql`
        query GetTokenPrices($tokens: [TokenPriceInput]!) {
            getTokenPrices(tokens: $tokens)
        }
    `,
    GET_TOKEN_PRICE: gql`
        query GetTokenPrice($symbol: String!, $tokenAddr: String) {
            getTokenPrice(symbol: $symbol, token_addr: $tokenAddr)
        }
    `,
    GET_ONE: gql`
        query GetProject($slug: String) {
            retrieveProject(slug: $slug) {
                id
                slug
                announced
                events {
                    id
                    type
                    crowdfunding_type
                    name
                    start_date
                    chainId
                    saft_url
                    contract {
                        abi
                        address
                    }
                    challenge {
                        question
                        answers
                    }
                    token_price
                    total_allocation
                    refund_deadline
                    results_date
                    results_url
                    answer
                    uid
                    register_url
                    prize
                    how_to_participate
                    is_hidden
                    marketing_event {
                        image
                        title
                        link
                    }
                    ama_event {
                        start_date
                        end_date
                        title
                        link
                        type
                    }
                    event_details {
                        answer_hash
                        min_tier
                        durations
                        hardcap
                        vesting {
                            start
                            cliff
                            duration
                            initial_unlock_percent
                        }
                    }
                }
                teaser
                subtitle
                status
                name
                logo
                square_logo
                hero
                social_platforms {
                    type
                    url
                }
                token {
                    ticker
                    network
                    tge
                    fully_diluted_valuation
                    initial_market_cap
                    initial_market_cap_ex_liquidity
                    total_supply
                }
                description
                explanation
                faqs {
                    question
                    answer
                }
                links {
                    roadmap {
                        href
                        image
                    }
                    team {
                        href
                        image
                    }
                    tokenomics {
                        href
                        image
                    }
                    website {
                        href
                        image
                    }
                }
                created_at
            }
        }
    `,
    GET_PLATFORM_IMAGES: gql`
        query getProjectImages {
            retrieveImages {
                url
                id
            }
        }
    `,
    REGISTER_FOR_WHITELIST: gql`
        mutation registerForWhitelist(
            $event_id: Int!
            $event_answer: [String]
        ) {
            registerForWhitelist(
                event_id: $event_id
                event_answer: $event_answer
            )
        }
    `,
    IS_REGISTERED_FOR_WHITELIST: gql`
        query IsRegisteredForWhitelist($event_id: Int!) {
            isRegisteredForWhitelist(event_id: $event_id)
        }
    `,

    IS_UID_REGISTERED_FOR_WHITELIST: gql`
        query IsUidERegisteredForWhitelist($event_id: Int!, $user_id: String!) {
            isUidRegisteredForWhitelist(event_id: $event_id, user_id: $user_id)
        }
    `,
    GET_WHITELIST_SUMMARY: gql`
        query GetWhitelistSummary($event_id: Int!) {
            getWhitelistSummary(event_id: $event_id) {
                finalized
                num_whitelisted
                registered
                user_allocation {
                    address
                    allocation
                    allocation_wei
                    multiplier
                    power
                    power_wei
                    proof
                    refund_fee
                    tier
                    tier_name
                    user_id
                }
            }
        }
    `,

    RETRIEVE_MAIN_EVENT: gql`
        query retrieveMainEvent($name: ContentSectionType!) {
            retrieveContentSection(name: $name) {
                id
                name
                data {
                    key
                    value
                }
            }
        }
    `,
};
